
        import V from 'voUtils/V.js';
        import Vue from 'vue';
        import {voMsg,voMsgPl} from './voUtils/voI18n.js';
        import DateEx from './voUtils/DateEx.js';

        Vue.prototype.VOFFICE = window.VOFFICE;

        var voFormatDate = DateEx.formatDate;

        import AirbnbStyleDatepicker from 'base/vue-airbnb-style-datepicker'
        import 'base/vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.min.css'

        var vueApps = {};

        var methods = {voMsg,voMsgPl,voFormatDate};

        
            import App0 from './app/unit.js';
            vueApps['app/unit.js'] = App0;
        

            import App1 from './app/UnitsOfTheDay.js';
            vueApps['app/UnitsOfTheDay.js'] = App1;
        

            import App2 from './app/dummy.js';
            vueApps['app/dummy.js'] = App2;
        

            import App3 from './app/search.js';
            vueApps['app/search.js'] = App3;
        

            import App4 from './app/navbar.js';
            vueApps['app/navbar.js'] = App4;
        

            import App5 from './app/SimilarUnits.js';
            vueApps['app/SimilarUnits.js'] = App5;
        

            import App6 from './app/LastViewedUnits.js';
            vueApps['app/LastViewedUnits.js'] = App6;
        

            import App7 from './app/UnitsForPets.js';
            vueApps['app/UnitsForPets.js'] = App7;
        

            import App8 from './app/VillaMitPool.js';
            vueApps['app/VillaMitPool.js'] = App8;
        

            import App9 from './app/region.js';
            vueApps['app/region.js'] = App9;
        

            import App10 from './app/UnitsForFamilienurlaub.js';
            vueApps['app/UnitsForFamilienurlaub.js'] = App10;
        

            import App11 from './app/blogUnits.js';
            vueApps['app/blogUnits.js'] = App11;
        

            import App12 from './app/BewertungenUnits.js';
            vueApps['app/BewertungenUnits.js'] = App12;
        

            import App13 from './app/strandlaubUnits.js';
            vueApps['app/strandlaubUnits.js'] = App13;
        

        
                import Comp14 from './comp/ImgFallback.vue';
                Vue.component('img-fallback',Comp14);
            

                import Comp15 from './comp/Booking.js';
                Vue.component('voffice-booking',Comp15);
            

                import Comp16 from './comp/DocBox.vue';
                Vue.component('voffice-doc-box',Comp16);
            

                import Comp17 from './comp/ErrorMessage.vue';
                Vue.component('voffice-error-message',Comp17);
            

                import Comp18 from './comp/FavElement.vue';
                Vue.component('voffice-fav-element',Comp18);
            

                import Comp19 from './comp/GuestBox.vue';
                Vue.component('voffice-guestbox',Comp19);
            

                import Comp20 from './comp/childrenage.vue';
                Vue.component('childrenage',Comp20);
            

                import Comp21 from './comp/Voucher-Unit.vue';
                Vue.component('voffice-voucher-unit',Comp21);
            

                import Comp22 from './comp/DateRange.vue';
                Vue.component('voffice-daterange',Comp22);
            

                import Comp23 from './comp/MaxTextSize.js';
                Vue.component('voffice-max-text-size',Comp23);
            

                import Comp24 from './comp/PopupDialog.vue';
                Vue.component('voffice-popup-dlg',Comp24);
            

                import Comp25 from './videoIframe.vue';
                Vue.component('video-iframe',Comp25);
            

                import Comp26 from './comp/PhotoSwipe.js';
                Vue.component('voffice-photoswipe',Comp26);
            

                import Comp27 from './comp/feedback-carousel.vue';
                Vue.component('feedback-carousel',Comp27);
            

                import Comp28 from './comp/searchUnitByName.vue';
                Vue.component('search-unit-name',Comp28);
            

                import Comp29 from './comp/DateRangeExt.vue';
                Vue.component('voffice-daterange-ext',Comp29);
            

                import Comp30 from './comp/blogtags.vue';
                Vue.component('blog-tags',Comp30);
            

                import Comp31 from './comp/CookieBox.vue';
                Vue.component('voffice-cookie-box',Comp31);
            

                import Comp32 from './comp/seasonprices.vue';
                Vue.component('seasonprices',Comp32);
            

                import Comp33 from './formatcurrency.vue';
                Vue.component('format-currency',Comp33);
            

                import Comp34 from './comp/SearchFilterTokens.vue';
                Vue.component('search-filter-tokens',Comp34);
            

                import Comp35 from './imageCarousel.vue';
                Vue.component('img-carousel',Comp35);
            

                import Comp36 from './pricehelp.vue';
                Vue.component('price-help',Comp36);
            

                import Comp37 from './comp/PopupBox.vue';
                Vue.component('voffice-popup-box',Comp37);
            

                import Comp38 from './comp/Voucher.vue';
                Vue.component('voffice-voucher',Comp38);
            

                import Comp39 from './comp/gmap.js';
                Vue.component('voffice-gmap',Comp39);
            

                import Comp40 from './comp/SearchFilterTokens-v2.vue';
                Vue.component('search-filter-tokens-v2',Comp40);
            

                import Comp41 from './offershelp.vue';
                Vue.component('offer-help',Comp41);
            

                import Comp42 from './comp/ContactForm.js';
                Vue.component('voffice-form',Comp42);
            

                import Comp43 from './teamMember.vue';
                Vue.component('voffice-team-member',Comp43);
            

        Vue.mixin({
            methods: methods
        });

        const datepickerOptions = {
            days: voMsg('weekdayslist').split(','),
            daysShort: voMsg('weekdayslist.short').split(','),
            monthNames: voMsg('monthlist.full').split(',')
        };
        Vue.use(AirbnbStyleDatepicker, datepickerOptions);


    	var passThrough = function (val) {
			return val || '';
		};

    	var filterCodes = [
    	    'vlang',
    	    'vautolang',
    	    'vsoption',
    	    'vsPropOption'
    	  ];

        for (let fc of filterCodes) {
    		Vue.filter(fc, passThrough);
    	};


        var vueAppEls = document.body.querySelectorAll('[vo-vue-app]');

        for (let el of vueAppEls) {
            let vName = el.getAttribute("vo-vue-app");
            let v = vueApps[vName];
            if (v) {
                v.el = el;
                V.log("creating vue app",vName);

                let app = new Vue(v);
            }

        }

        VOFFICE.afterAllLoaded = function(){
            document.documentElement.classList.add("app-loaded");
        };

        window.addEventListener('beforeunload', function (event) {
        	document.documentElement.classList.add("app-leaving");
        });


    